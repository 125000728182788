<template>
  <Toolbar class="mb-4">
    <template #start>
      <div v-for="organization of organizations" :key="organization.id">
        <div v-if="organization.id === id" class="flex align-items-center">
          <Image
            v-if="organization.logo"
            imageStyle="width: 4em;"
            :src="baseUrl + '/' + organization.logo"
            :alt="organization.name + 'logo'"
          />
          <i
            v-else
            class="pi pi-desktop text-4xl text-blue-500 shadow-2 inline-block surface-card"
            style="border-radius: 10px; padding: 10px; margin: 5px"
          />
          <label style="padding: 10px; font-weight: bold; font-size: 20px">{{
            organization.name
          }}</label>
        </div>
      </div>
    </template>
    <template #end>
      <div class="layout-menu-container">
        <Menubar :model="items" style="border: none" />
      </div>
    </template>
  </Toolbar>
</template>

<script>
import OrganizationService from '../service/OrganizationService';

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      items: [
        {
          label: 'Reservas',
          icon: 'pi pi-fw pi-bookmark',
          to: this.getOrganizationRoute() + `/`,
        },
        {
          label: 'Plantillas de notificaciones',
          icon: 'pi pi-fw pi-envelope',
          items: [
            {
              label: 'Reserva creada por cliente',
              icon: 'pi pi-fw pi-plus',
              to: this.getOrganizationRoute() + `/notification/CREATE_BOOKING_AUTO`,
            },
            {
              label: 'Reserva creada por punto de venta',
              icon: 'pi pi-fw pi-plus',
              to: this.getOrganizationRoute() + `/notification/CREATE_BOOKING_MANUAL`,
            },
            {
              label: 'Reserva aceptada',
              icon: 'pi pi-fw pi-check',
              to: this.getOrganizationRoute() + `/notification/ACCEPT_BOOKING`,
            },
            {
              label: 'Reserva rechazada',
              icon: 'pi pi-fw pi-times',
              to: this.getOrganizationRoute() + `/notification/REJECT_BOOKING`,
            },
            {
              label: 'Reserva cancelada',
              icon: 'pi pi-fw pi-ban',
              to: this.getOrganizationRoute() + `/notification/CANCEL_BOOKING`,
            },
            {
              label: 'Recordatorio de reserva',
              icon: 'pi pi-fw pi-calendar-plus',
              to: this.getOrganizationRoute() + `/notification/REMIND_BOOKING`,
            },
          ],
        },
        {
          label: 'Widget',
          icon: 'pi pi-fw pi-briefcase',
          to: this.getOrganizationRoute() + `/widget`,
        },
        {
          label: 'Historial de notificaciones',
          icon: 'pi pi-fw pi-briefcase',
          to: this.getOrganizationRoute() + `/history`,
        },
        {
          label: 'Configuración',
          icon: 'pi pi-fw pi-cog',
          items: [
            {
              label: 'Notificaciones',
              icon: 'pi pi-fw pi-envelope',
              to: this.getOrganizationRoute() + `/config/notifications`,
            },
            {
              label: 'Disponibilidad',
              icon: 'pi pi-fw pi-clock',
              to: this.getOrganizationRoute() + `/config/calendar`,
            },
            {
              label: 'Formulario de reserva',
              icon: 'pi pi-fw pi-inbox',
              to: this.getOrganizationRoute() + `/config/form`,
            },
            {
              label: 'Punto de venta',
              icon: 'pi pi-fw pi-inbox',
              to: this.getOrganizationRoute() + `/config/organization`,
            },
          ],
        },
        {
          label: 'Lista negra',
          icon: 'pi pi-fw pi-ban',
          to: this.getOrganizationRoute() + `/blacklist`,
        },
      ],

      id: this.getOrganizationId(),
      organizations: null,
    };
  },
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService
      .getOrganizationList()
      .then((data) => (this.organizations = data));
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    getOrganizationId() {
      let route = this.$route.path + '';
      let url = route.split('/');
      let id = url[2];
      return id;
    },
    getOrganizationRoute() {
      let id = this.getOrganizationId();
      let route = '/organization/' + id;
      return route;
    },
  },
};
</script>
